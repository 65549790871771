.App {
  display: flex;
  height: auto;
}

// Navigation
.MobileNavigation {
  display: none;
}

.Navigation {
  width: 20%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 50px;
}

.Menu {
  width: 100%;
  float: left;
  // position: fixed;

  ul {
    margin: 0px;
    list-style-type: none;

    li {
      margin: 0px;
      width: 100%;
      float: left;

      a {
        text-decoration: none;
        color: #767676;
        display: inline-block;
        font-weight: 500;
        font-family: "Montserrat";
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 30px;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;

        &.active, &:hover {
          color: #000;
          letter-spacing: 2px;
        }
      }
    }
  }
}

// Right side content
.Content {
  width: 80%;
  height: 100%;
  background-color: #f8f8f8;
  border-left: 1px solid #ebebeb;
}

.Section {
  transition: transform .3s ease .3s,opacity 0s ease .3s;
}

.VisitCard {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.Avatar {
  height: 300px;
  width: 300px;
  position: relative;
  border-radius: 100%;
}

.Image {
  background-image: url('./img/avatar.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
  animation: morph 8s ease-in-out infinite;
  background-blend-mode: multiply;
  box-shadow: inset 0px 0px 0px 9px rgb(255 255 255 / 30%);
  // filter: grayscale(100%)
}

.Details {
  margin-left: 40px;
}

.Name {
  font-size: 45px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 0 14px 0;
  line-height: 1.4;
  font-family: "Montserrat";
}

.Job {
  font-family: 'Montserrat';
  font-style: italic;
  font-size: 20px;
  font-weight: 500;
  max-width: 450px;
  margin-bottom: 30px;
  color:   #767676;
  line-height: 30px;
  letter-spacing: 0.5px;
}

.SocialLinks {
  width: 100%;
  float: left;

  ul {
    margin: 0px;
    list-style-type: none;
    padding: 0;

    li {
      margin: 0px 12px 0px 0px;
      display: inline-block;

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

// About page
.AboutPage {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 100px 0px;
}

// Portfolio
.Portfolio {
  span {
    display: inline-block;
    background-color: rgba(0,0,0,.04);
    text-transform: uppercase;
    padding: 4px 10px;
    font-weight: 600;
    font-size: 12px;
    color: #333;
    font-family: "Montserrat";
    letter-spacing: 0px;
    margin-bottom: 11px;
    background-color: rgba(0,0,0,.04);
  }

  .List {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .Project {
    min-height: 350px;
    width: calc(50% - 60px);
    background-color: honeydew;
    margin: 30px;
  }
}

// Tablet and mobile 
@media only screen and (max-width: 1024px) {
  .App {
    flex-direction: column;
  }

  .Navigation {
    display: none;
  }

  .MobileNavigation {
    display: flex;
    padding: 20px;
    justify-content: flex-end;

    ul {
      margin: 0px;
      list-style-type: none;
      padding: 0;
  
      li {
        display: inline;
        margin-left: 10px;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .Content {
    width: 100%;
    padding: 0;
    border: none;
    padding-top: 15px;
  }

  .VisitCard {
    flex-direction: column;

    .Avatar {
      margin-bottom: 30px;
    }

    .Details {
      margin: 0;
      text-align: center;
    }
  }
}
